import React, { useState, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsArrowUpDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import * as S from "../styled/styles";
import * as S2 from "./styled/styled";

import Layout from "../../../components/Layout";
import HeaderArmazem from "../../../components/HeaderArmazem";
import ModalExport from "../components/ModalExport";
import LineTable from "../../../components/Lines/LineTable";
import Paginate from "../../../components/Paginate/Paginate";
import requests from "../../../services/requests";
import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import ExportarIcon from "../../../assets/svgs/exportar.inline.svg";
import Modal from "../../../components/Modals";
import ReceiptTimingGraph from "../../../components/Graphs/ReceiptTimingGraph";
import ReceiptOrderTrackingGraph from "../../../components/Graphs/ReceiptOrderTrackingGraph";
import ReceiptProductTrackingGraph from "../../../components/Graphs/ReceiptProductTrackingGraph";
import GraphWarehouseSla from "../../../components/Graphs/GraphWarehouseSla";

import XpandContainer from "../../../components/XpandContainer";
const IndexPage = () => {
    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(
        window.localStorage.getItem("warehouse-filter-client")
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-client"))
            : []
    );
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [action, setAction] = useState(1);
    const [load, setLoad] = useState(false);
    const [loadMetrics, setLoadMetrics] = useState(false);
    const [list, setList] = useState([]);
    const [searchNfe, setSearchNfe] = useState(null);
    const [selectedUf, setSelectedUf] = useState(
        JSON.parse(window.localStorage.getItem("warehouse-filter-state"))?.value !== null
            ? JSON.parse(window.localStorage.getItem("warehouse-filter-state"))
            : { value: null, label: "TODOS" }
    );
    const searchPeriod = useSelector(state => state.period);
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [data, setData] = useState({});
    const [orderList, setOrderList] = useState(null);
    const [modalExport, setModalExport] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [statusPedido, setStatusPedido] = useState(null);
    const [statusGrafico, setStatusGrafico] = useState(null);
    const [detalheOr, setDetalheOr] = useState([]);
    const [modalDetails, setModalDetails] = useState(false);
    const [openModalGraphReceipt, setOpenModalGraphReceipt] = useState(false);

    //Função que gera arquivo para exportar lista
    const downloadList = () => {
        setIsDownloading(true);
        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            nfeNumber: searchNfe,
            statusPedido,
            statusGrafico,
            order: orderList,
        };

        var date = new Date();

        requests
            .exportListRecebimento(filters)
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(`"download", 'Recebimento' + '-' + ${date.getFullYear()} + ${date.getMonth()} + ${date.getDate()} + '.xlsx'`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloading(false);
                setModalExport(false);
            });
    };

    //Função que gera os gráficos
    const showMetrics = () => {
        setData({});
        setLoadMetrics(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            statusPedido,
            statusGrafico,
        };

        requests.dashboardRecebimento(filters).then(data => {
            if (!data.data.average) {
                data.data.average = "0";
            }

            setData(data.data);
        });

        setLoadMetrics(false);
    };

    //Função que gera a listagem
    const listRecebimento = () => {
        setLoad(true);

        var filters = {
            ...searchPeriod,
            idArmazem: selectedUf?.value,
            clientsWarehouse: selectedClient,
            nfeNumber: searchNfe,
            statusPedido,
            statusGrafico,
            order: orderList,
        };

        requests.listRecebimento(filters, meta.current_page > 0 ? meta.current_page : 1).then(data => {
            setList(data.data);
            setMeta(data.meta);
            setLoad(false);
        });
    };

    //Função que gera a lista de clientes do filtro
    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (selectedClient?.length > 0 && search.length < 2) {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        } else if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listClientsWithArmazem(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return (
                            aux.push({
                                value: client.identifier,
                                label: client.name + " (" + client.documentNumberFormatted + ")",
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    const handleOpenModal = () => {
        setModalDetails(true);
    };

    const handleSearchNfe = number => {
        setSearchNfe(number);
    };

    const validateOrder = (object, column) => {
        if (object?.order_campo === column) {
            return true;
        } else {
            return false;
        }
    };

    const handleOrder = (campo, order = 0) => {
        if (order > 0) {
            if (order === 3) {
                if (orderList?.order_campo === campo && orderList?.order === "DESC") {
                    setOrderList({
                        order_campo: campo,
                        order: "ASC",
                    });
                } else if (orderList?.order_campo === campo && orderList?.order === "ASC") {
                    setOrderList(null);
                } else {
                    setOrderList({
                        order_campo: campo,
                        order: "DESC",
                    });
                }
            } else {
                setOrderList({
                    order_campo: campo,
                    order: order === 1 ? "DESC" : "ASC",
                });
            }
        } else {
            setOrderList(null);
        }
    };

    const actionModalGraphReceipt = () => {
        setOpenModalGraphReceipt(!openModalGraphReceipt);
    };

    // const closeModalGraphReceipt = () => {
    //     setOpenModalGraphReceipt(false);
    // };

    useEffect(() => {
        loadClientOptions();
    }, []);

    useEffect(() => {
        if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
            showMetrics();
            listRecebimento();
        }
    }, [searchPeriod, selectedClient, selectedUf, statusGrafico]);

    useEffect(() => {
        if (searchPeriod?.startsAt != null || searchPeriod?.endsAt != null) {
            listRecebimento();
        }
    }, [action, statusPedido, searchNfe, orderList]);

    return (
        <>
            <Layout>
                <ModalExport
                    modalExport={modalExport}
                    actionModalExport={actionModalExport}
                    isDownloading={isDownloading}
                    downloadList={downloadList}
                    title="Recebimento"
                />

                <HeaderArmazem
                    title="Armazém > Recebimento"
                    clientsOptions={clientsOptions}
                    setSelectedClient={setSelectedClient}
                    noClientOptionsMessage={noClientOptionsMessage}
                    loadClientOptions={loadClientOptions}
                    isLoadingClients={isLoadingClients}
                    setSelectedUf={setSelectedUf}
                    setMeta={setMeta}
                    handleStatusGrafico={setStatusGrafico}
                    handleStatusPedido={setStatusPedido}
                />

                <S.ContainerCards className="flex flex-wrap p-4">
                    <Card className="w-3/12 md-full">
                        <ReceiptTimingGraph
                            title="Prazos do recebimento"
                            searchPeriod={searchPeriod}
                            selectedClient={selectedClient}
                            selectedUf={selectedUf}
                            handleStatus={setStatusGrafico}
                            statusGrafico={statusGrafico}
                            statusPedido={statusPedido}
                            setMeta={setMeta}
                        />
                    </Card>
                    <Card className="w-3/12 md-full">
                        <ReceiptOrderTrackingGraph
                            title="Tracking de pedido"
                            searchPeriod={searchPeriod}
                            selectedClient={selectedClient}
                            selectedUf={selectedUf}
                            handleStatus={setStatusPedido}
                            statusGrafico={statusGrafico}
                            statusPedido={statusPedido}
                            setMeta={setMeta}
                        />
                    </Card>
                    <Card className="w-3/12 md-full">
                        <ReceiptProductTrackingGraph
                            title="Tracking de produtos"
                            searchPeriod={searchPeriod}
                            selectedClient={selectedClient}
                            selectedUf={selectedUf}
                            handleStatus={setStatusPedido}
                            statusGrafico={statusGrafico}
                            statusPedido={statusPedido}
                            setMeta={setMeta}
                        />
                    </Card>
                    <Card className="w-3/12 md-full">
                        {!data.average ? (
                            <>
                                <Card>
                                    <TitleC6>Análise de remessas (média)</TitleC6>
                                    <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                        <ClipLoader size={20} loading={true} />
                                    </div>
                                </Card>
                            </>
                        ) : (
                            <GraphWarehouseSla
                                title="Análise de remessas (média)"
                                data={data}
                                isLoading={load}
                                selectedClient={selectedClient}
                                container="GraphSlaReceiptTotal"
                                modality="byTotal"
                                openModalGraphReceipt={openModalGraphReceipt}
                                actionModalGraphReceipt={actionModalGraphReceipt}
                            />
                        )}
                    </Card>
                </S.ContainerCards>
                {openModalGraphReceipt && (
                    <Modal
                        onClose={actionModalGraphReceipt}
                        size="xs:w-11/12 lg:w-8/12 min-h-20%"
                        show={openModalGraphReceipt}
                        scIn={false}
                        title={
                            <div className="flex items-center">
                                <h5
                                    className={`mr-6 text-roxo_oficial font-bold`}>
                                    Análise de remessas por dia {`${clientsOptions.find(arr => selectedClient === arr.value) ? "-" + " " + clientsOptions.find(arr => selectedClient === arr.value)?.label : ""}`}
                                </h5>
                            </div>
                        }
                    >
                        <div className="w-full">
                            {!data.average ? (
                                <>
                                    <Card>
                                        <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                                            <ClipLoader size={20} loading={true} />
                                        </div>
                                    </Card>
                                </>
                            ) : (
                                <GraphWarehouseSla
                                    title="Análise de remessas por dia"
                                    data={data}
                                    isLoading={load}
                                    selectedClient={selectedClient}
                                    container="GraphSlaReceiptPeriod"
                                    modality="byPeriod"
                                    openModalGraphReceipt={openModalGraphReceipt}
                                    actionModalGraphReceipt={actionModalGraphReceipt}
                                />
                            )}
                        </div>
                    </Modal>
                )}

                <div className="p-4">
                    <Card>
                        <S.Table>
                            <S.TableHeader>
                                <S.ContainerFluid>
                                    <S.ContainerSection>
                                        <TitleC6>Recebimento</TitleC6>
                                        <S.ContainerSearch>
                                            <S.ContainerInput>
                                                <BiSearch color="#00000087" />
                                                <S.Input
                                                    placeholder="Buscar por NFE ou OR"
                                                    onChange={event => handleSearchNfe(event.target.value)}
                                                />
                                            </S.ContainerInput>
                                        </S.ContainerSearch>
                                    </S.ContainerSection>
                                    <S.ButtonIcon>
                                        <S.ButtonIconText onClick={() => setModalExport(!modalExport)}>
                                            Exportar
                                        </S.ButtonIconText>
                                        <ExportarIcon />
                                    </S.ButtonIcon>
                                </S.ContainerFluid>
                            </S.TableHeader>
                            <div>
                                <XpandContainer>
                                    <S.LineHeadTable>
                                        <S.ColumnHeadTable width="150">
                                            <S.LineHeadText className="text-roxo_oficial">Nota Fiscal</S.LineHeadText>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="75">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.idOrdemRecto", 3)}
                                                cursor>
                                                OR
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.idOrdemRecto") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.idOrdemRecto", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.idOrdemRecto", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.idOrdemRecto", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="400">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nomeRemetente", 3)}
                                                cursor
                                            >
                                                Remetente
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nomeRemetente") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeRemetente", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeRemetente", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.nomeRemetente", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="400">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.nomeDestinatario", 3)}
                                                cursor
                                            >
                                                Destinatário
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.nomeDestinatario") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeDestinatario", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.nomeDestinatario", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.nomeDestinatario", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="130">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.idDi", 3)}
                                                cursor
                                            >
                                                DI
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.idDi") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.idDi", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.idDi", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.idDi", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="150">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.origem", 3)}
                                                cursor
                                            >
                                                Origem
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.origem") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.origem", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.origem", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.origem", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="120">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.ufArmazem", 3)}
                                                cursor
                                            >
                                                Armazém
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.ufArmazem") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.ufArmazem", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.ufArmazem", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.ufArmazem", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="220">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.dtInicio", 3)}
                                                cursor
                                            >
                                                Início de recebimento
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.dtInicio") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.dtInicio", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.dtInicio", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.dtInicio", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="250">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.dtFim", 3)}
                                                cursor
                                            >
                                                Término de recebimento
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.dtFim") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown size={20} onClick={() => handleOrder("e.dtFim", 2)} />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.dtFim", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.dtFim", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="150">
                                            <S.LineHeadText className="text-roxo_oficial">Volumes</S.LineHeadText>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="150">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.idProcesso", 3)}
                                                cursor
                                            >
                                                Processo
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.idProcesso") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.idProcesso", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp
                                                            size={20}
                                                            onClick={() => handleOrder("e.idProcesso", 0)}
                                                        />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown
                                                        size={20}
                                                        onClick={() => handleOrder("e.idProcesso", 1)}
                                                    />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                        <S.ColumnHeadTable width="150">
                                            <S.LineHeadText
                                                className="text-roxo_oficial"
                                                onClick={() => handleOrder("e.situacao", 3)}
                                                cursor
                                            >
                                                Status
                                            </S.LineHeadText>
                                            <S.IconCursor>
                                                {validateOrder(orderList, "e.situacao") ? (
                                                    orderList.order === "DESC" ? (
                                                        <BsArrowDown
                                                            size={20}
                                                            onClick={() => handleOrder("e.situacao", 2)}
                                                        />
                                                    ) : (
                                                        <BsArrowUp size={20} onClick={() => handleOrder("e.situacao", 0)} />
                                                    )
                                                ) : (
                                                    <BsArrowUpDown size={20} onClick={() => handleOrder("e.situacao", 1)} />
                                                )}
                                            </S.IconCursor>
                                        </S.ColumnHeadTable>
                                    </S.LineHeadTable>
                                    {!load && (
                                        <>
                                            {list &&
                                                list.map((entity, key) => {
                                                    return (
                                                        <LineTable
                                                            key={key}
                                                            openModal={handleOpenModal}
                                                            entity={entity}
                                                            setDetalheOr={setDetalheOr}
                                                        />
                                                    );
                                                })}
                                        </>
                                    )}
                                    {load ? (
                                        <div className="text-center mt-4">
                                            <ClipLoader size={40} loading={load} />
                                        </div>
                                    ) : (
                                        <>
                                            {list.length === 0 && (
                                                <div className="text-center mt-4">Nenhum dado encontrado</div>
                                            )}
                                        </>
                                    )}
                                    {meta.total_pages > 1 && (
                                        <Paginate
                                            meta={meta}
                                            setMeta={setMeta}
                                            action={action}
                                            setAction={setAction}
                                            showDetails={true}
                                        />
                                    )}
                                </XpandContainer>
                            </div>
                        </S.Table>
                    </Card>
                </div>
            </Layout>
            {modalDetails && (
                <S2.ContainerModal>
                    <S2.Modal>
                        <S2.ModalHeader>
                            <S2.ModalHeaderTitle>Detalhes do recebimento</S2.ModalHeaderTitle>
                            <S2.CloseIcon onClick={() => setModalDetails(false)}>X</S2.CloseIcon>
                        </S2.ModalHeader>
                        <S2.TableInformationContainer>
                            <S2.TableInformation>
                                <S2.TableInformationHeader>
                                    <S2.InformationHeaderText>Informações da or</S2.InformationHeaderText>
                                </S2.TableInformationHeader>
                                <S2.LineTableInformation>
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>OR</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableStrong>{detalheOr?.idOrdemRecto}</S2.TextTableStrong>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation color="#E6E3F0">
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Recebido em:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.dtFimFormatted}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation>
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Cliente:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.clienteText}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation color="#E6E3F0">
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Transportadora:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.transportadora}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation>
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Tipo de veículo:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.tipoVeiculo}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation color="#E6E3F0">
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Placa:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.placa}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation>
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Motorista:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.motorista}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation color="#E6E3F0">
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Observações:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.observacao}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation color="#E6E3F0">
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Informações adicionais:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>
                                            <ul>
                                                <li>
                                                    <S2.Link href={`http://nestprd.seniorcloud.com.br:8080/wmsweb/infoentrada/?local=relatorioRda&idOr=${detalheOr?.idOrdemRecto}`} target="_blanck">
                                                        Clique aqui para ver as <b>Fotos</b>
                                                    </S2.Link>
                                                </li>
                                                <li>
                                                    <S2.Link href={`http://nestprd.seniorcloud.com.br:8080/wmsweb/infoentrada/?local=relatorioResultadoConferencia&idOr=${detalheOr?.idOrdemRecto}`} target="_blanck">
                                                        Clique aqui para ver o <b>Termo de Faltas e Avaria (TFA)</b>
                                                    </S2.Link>
                                                </li>
                                            </ul>
                                        </S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.TableInformationHeader>
                                    <S2.InformationHeaderText>material sujeito a conferência</S2.InformationHeaderText>
                                </S2.TableInformationHeader>
                                <S2.LineTableInformation>
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Volumes:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.qtdVolume}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation color="#E6E3F0">
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Peso bruto:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>{detalheOr?.peso}</S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.LineTableInformation>
                                    <S2.ColumnTableInformation1>
                                        <S2.TextTableStrong>Notas Fiscais:</S2.TextTableStrong>
                                    </S2.ColumnTableInformation1>
                                    <S2.ColumnTableInformation2>
                                        <S2.TextTableColumn2>
                                            {detalheOr?.notas && detalheOr?.notas?.length > 0 ? (
                                                detalheOr?.notas?.map((entity, key) => {
                                                    return <span className="mr-2">{entity?.idNota}</span>;
                                                })
                                            ) : (
                                                <></>
                                            )}
                                        </S2.TextTableColumn2>
                                    </S2.ColumnTableInformation2>
                                </S2.LineTableInformation>
                                <S2.TableInformationHeader>
                                    <S2.InformationHeaderText>{detalheOr?.dtRecebidoFormatted}</S2.InformationHeaderText>
                                </S2.TableInformationHeader>
                                <S2.BoxText>
                                    <S2.Text>
                                        Declaro que as informações referentes a mercadoria descritas acima, estão corretas e
                                        condizem com o estado do material no momento do recebimento na VENDEMMIA ARMAZEM E
                                        LOGISTICA INTEGRADA LTDA.
                                    </S2.Text>
                                </S2.BoxText>
                                <S2.BoxName>
                                    <S2.TextTableStrong>Conferente: {detalheOr?.conferente}</S2.TextTableStrong>
                                </S2.BoxName>
                            </S2.TableInformation>
                        </S2.TableInformationContainer>
                    </S2.Modal>
                </S2.ContainerModal>
            )}
        </>
    );
};

export default IndexPage;
