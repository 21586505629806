import styled from "styled-components";

export const CardInfoLineHeader = styled.div``;

export const CircleRed = styled.div`
    width: 11px;
    height: 11px;
    background: ${props => (props.color ? props.color : "#06F90E")};
    border-radius: 50%;
    margin-right: 10px;
`;
