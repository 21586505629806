import React, { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Link } from "gatsby";

import * as S from "../styles";
import * as S2 from "./styles";

const LineTable = ({ openModal, entity, setDetalheOr }) => {
    const [modalLineTable, setModalLineTable] = useState(false);

    const handleModalLineTable = () => {
        setModalLineTable(!modalLineTable);
    };

    const handleColor = status => {
        if (status === "Recebido") {
            return "#06F90E";
        } else if (status === "Em Recebimento") {
            return "#F8991C";
        } else if (status === "Interrompido") {
            return " #FB1B41";
        } else if (status === "Liberado") {
            return "#06F90E";
        } else if (status === "Bloqueado") {
            return "#FB1B41";
        } else {
            return "";
        }
    };

    return (
        <S.LineBodyTable key={entity?.id} className={modalLineTable ? "" : "border-b-2"}>
            <S.Columns onClick={handleModalLineTable}>
                <S.ColumnBodyTable width="150">
                    <S.BoxIcon>
                        {!modalLineTable ? <IoIosArrowForward color="#252525" /> : <IoIosArrowDown color="#252525" />}
                    </S.BoxIcon>
                    <S.LineBodyText>
                        {entity?.notas && entity?.notas?.length > 0 ? (
                            entity?.notas?.map((entity3, key) => {
                                return (
                                    <>
                                        {entity3?.idNota}
                                        <br />
                                    </>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="75" paddingRight="30">
                    <S.LineBodyText>{entity?.idOrdemRecto}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="400" paddingRight="30">
                    <S.LineBodyText>{entity?.nomeRemetente}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="400" paddingRight="30">
                    <S.LineBodyText>{entity?.destinatarioText}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="130">
                    <S.LineBodyText>{entity?.diNumberFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="150">
                    <S.LineBodyText>{entity?.origem}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="120">
                    <S.LineBodyText>{entity?.stateCodeFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="220">
                    <S.LineBodyText>{entity?.dtInicioFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="250">
                    <S.LineBodyText>{entity?.dtFimFormatted ? entity?.dtFimFormatted : entity?.prazo}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="150">
                    <S.LineBodyText color={handleColor(entity?.qtdVolumeFormatted)}>{entity?.qtdVolumeFormatted}</S.LineBodyText>
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="150">
                    {entity?.processCode && (
                        <S.LineBodyText>
                            <Link
                                to={`/processos/detalhes/${entity.processIdentifier}`}
                                className="w-full text-roxo_oficial text-center p-1 text-sm cursor-pointer">
                                {entity.processCode}
                            </Link>
                        </S.LineBodyText>
                    )}
                </S.ColumnBodyTable>
                <S.ColumnBodyTable width="150">
                    <S.LineBodyText color={handleColor(entity?.status)}>{entity?.status}</S.LineBodyText>
                </S.ColumnBodyTable>
            </S.Columns>
            {modalLineTable && (
                <S.CardInfoLine>
                    <S2.CardInfoLineHeader>
                        <S.ButtonExport
                            onClick={() => {
                                setDetalheOr(entity);
                                openModal();
                            }}
                        >
                            Detalhes OR
                        </S.ButtonExport>
                    </S2.CardInfoLineHeader>
                    <S.CardTable>
                        <S.CardTableLine>
                            <S.CardTableColumn width="240">
                                <S.LineHeadText>Código</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="500">
                                <S.LineHeadText>Descrição</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="130">
                                <S.LineHeadText>Quantidade</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="150">
                                <S.LineHeadText>Lote</S.LineHeadText>
                            </S.CardTableColumn>
                            <S.CardTableColumn width="150">
                                <S.LineHeadText>Estado do produto</S.LineHeadText>
                            </S.CardTableColumn>
                        </S.CardTableLine>
                        {entity?.itens && entity?.itens?.length > 0 ? (
                            entity?.itens?.map((entity2, key) => {
                                return (
                                    <S.CardTableLineBody>
                                        <S.CardTableColumnBody width="240">
                                            <S.LineBodyText>{entity2?.codProduto}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="500">
                                            <S.LineBodyText>{entity2?.descricao}.</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="130" align="right" paddingRight="40">
                                            <S.LineBodyText>{entity2?.qtdeEntradaFormatted}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="150">
                                            <S.LineBodyText>{entity2?.loteFabricacao}</S.LineBodyText>
                                        </S.CardTableColumnBody>
                                        <S.CardTableColumnBody width="150" flex="Sim">
                                            <S2.CircleRed color={handleColor(entity2?.estadoText)} />
                                            <S.LineBodyText color={handleColor(entity2?.estadoText)}>
                                                {entity2?.estadoText}
                                            </S.LineBodyText>
                                        </S.CardTableColumnBody>
                                    </S.CardTableLineBody>
                                );
                            })
                        ) : (
                            <S.LineBodyTextCenter>Nenhum dado encontrado</S.LineBodyTextCenter>
                        )}
                    </S.CardTable>
                </S.CardInfoLine>
            )}
        </S.LineBodyTable>
    );
};

export default LineTable;
