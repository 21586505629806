import styled from "styled-components";

export const BoxName = styled.div`
    padding: 0 24px;
    margin-bottom: 48px;
`;

export const BoxText = styled.div`
    margin: 0 24px 28px;
    padding-bottom: 23px;
    border-bottom: 1px solid #353535;
`;

export const ContainerModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 52;
    background: #50505066 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ColumnTableInformation1 = styled.div`
    min-width: 160px;
`;

export const ColumnTableInformation2 = styled.div``;

export const ModalHeaderTitle = styled.p`
    text-transform: uppercase;
    font-weight: 500;
    color: #707070;
    font-size: 16px;
`;

export const CloseIcon = styled(ModalHeaderTitle)`
    cursor: pointer;
    margin-bottom: 10px;
`;

export const InformationHeaderText = styled.p`
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
`;

export const LineTableInformation = styled.div`
    display: flex;
    padding: 0 24px;
    background: ${props => (props.color ? props.color : "")};
`;

export const Modal = styled.div`
    background: #f5f5f5;
    width: 100%;
    max-width: 1002px;
    /* height: 961px; */
    border-radius: 12px;
    padding: 26px 41px;
    box-sizing: border-box;
`;

export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const TableInformation = styled.div`
    width: 620px;
    margin: 30px auto 0;
    border: 1.5px solid #a6a2a280;
    border-top: none;
`;

export const TableInformationContainer = styled.div`
    max-height: 480px;
    overflow-y: scroll;
`;

export const TableInformationHeader = styled.div`
    background: #3f2f72;
    text-align: center;
    padding: 3px;
    margin-bottom: 10px;
    margin-top: 22px;
`;

export const TextTable = styled.p`
    font-size: 14px;
    font-weight: normal;
`;

export const TextTableStrong = styled(TextTable)`
    font-weight: bold;
    color: #353535;
`;

export const TextTableColumn2 = styled(TextTable)`
    text-transform: uppercase;
`;

export const Text = styled.p`
    font-size: 14px;
    font-weight: normal;
`;


export const Link = styled.a`
    text-decoration: underline;
`;
