import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";

import requests from "../../../services/requests";
import TitleC6 from "../../../components/Typography/TitleC6";
import Card from "../../../components/Cards";
import { customLegend, customMarkers } from "../shared/layout";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import am4lang_pt_BR from "@amcharts/amcharts4/lang/pt_BR";
am4core.useTheme(am4themesAnimated);

export default function ReceiptProductTrackingGraph({
    title,
    searchPeriod,
    selectedClient,
    selectedUf,
    statusGrafico,
    statusPedido,
    handleStatus,
    setMeta,
    dashboard = false,
}) {
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(true);
    const filtered = useRef(null);

    const x = useRef(null);

    const getData = useCallback(async chart => {
        if (searchPeriod?.startsAt !== null || searchPeriod?.endsAt !== null) {
            requests
                .dashboardRecebimento({
                    ...searchPeriod,
                    idArmazem: selectedUf?.value,
                    clientsWarehouse: selectedClient,
                    statusPedido,
                    statusGrafico,
                })
                .then(data => {
                    setIsLoading(false);
                    chart.data = [
                        {
                            status: "Liberados",
                            qtt: data.data.liberados,
                        },
                        {
                            status: "Bloqueados",
                            qtt: data.data.bloqueados,
                        },
                    ];
                });
        }

        chart.data = [];
    });

    useLayoutEffect(() => {
        setIsLoading(true);
        const chart = am4core.create("ReceiptProductTrackingGraph", am4charts.PieChart);
        chart.language.locale = am4lang_pt_BR;

        chart.paddingBottom = "20px";

        chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        chart.radius = am4core.percent(70);
        chart.innerRadius = am4core.percent(40);
        // chart.startAngle = 180;
        // chart.endAngle = 360;

        let series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "qtt";
        series.dataFields.category = "status";
        series.labels.template.disabled = true;

        series.slices.template.cornerRadius = 0;
        series.slices.template.innerCornerRadius = 0;
        series.slices.template.draggable = false;
        series.slices.template.inert = true;
        series.alignLabels = false;

        var colorSet = new am4core.ColorSet();
        colorSet.list = [am4core.color("#06f90e"), am4core.color("#FB1B41")];
        series.colors = colorSet;

        series.slices.template.propertyFields.isActive = "pulled";

        // chart.legend = new am4charts.Legend();
        const legend = customLegend(chart);
        legend.itemContainers.template.togglable = false;
        customMarkers(legend);

        if (handleStatus) {
            let tActiveState = series.slices.template.states.create("active");
            series.slices.template.events.on("hit", function (ev) {
                let slice = ev.target;
                let category = ev.target.dataItem.properties.category;

                series.slices.each(function (item, key) {
                    if (category === "Liberados") {
                        if (!dashboard) {
                            setMeta({
                                current_page: 1,
                            });
                        }

                        if (key === 0) {
                            if (filtered.current && filtered.current.length > 0 && filtered.current === "liberados") {
                                handleStatus(null);
                                filtered.current = null;

                                item.isActive = false;
                            } else {
                                handleStatus("liberados");
                                filtered.current = "liberados";

                                item.isActive = true;
                            }
                        } else {
                            // Desativar o outro
                            item.isActive = false;
                        }
                    } else if (category === "Bloqueados") {
                        if (!dashboard) {
                            setMeta({
                                current_page: 1,
                            });
                        }

                        if (key === 1) {
                            if (filtered.current && filtered.current.length > 0 && filtered.current === "bloqueados") {
                                handleStatus(null);
                                filtered.current = null;

                                item.isActive = false;
                            } else {
                                handleStatus("bloqueados");
                                filtered.current = "bloqueados";

                                item.isActive = true;
                            }
                        } else {
                            // Desativar o outro
                            item.isActive = false;
                        }
                    }
                });

                series.slices.each(function (item, key) {
                    if (item.isActive || !filtered.current || filtered.current === null) {
                        if (key === 0) {
                            item.fill = am4core.color("#06f90e");
                        } else if (key === 1) {
                            item.fill = am4core.color("#FB1B41");
                        }
                    } else {
                        item.fill = am4core.color("#cecece");
                    }
                });
            });
        }

        getData(chart);

        x.current = chart;

        return () => {
            chart.dispose();
        };
    }, [searchPeriod, selectedClient, selectedUf]);

    return (
        <>
            {(isLoading || total > 0) && (
                <Card>
                    <TitleC6>{title}</TitleC6>
                    <div className="border-t border-C2 border-solid pt-4 overflow-hidden">
                        {isLoading && <ClipLoader size={20} loading={isLoading} />}

                        <div id="ReceiptProductTrackingGraph" style={{ width: "100%", height: "270px" }} />
                    </div>
                </Card>
            )}
        </>
    );
}
